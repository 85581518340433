/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
/* eslint-disable no-mixed-operators */
/* eslint-disable import/no-anonymous-default-export */
import { GQL } from 'fetchier'
import Cookie from 'js-cookie'
import { HASURA_KEY, HASURA_URL } from './config'

const orderItemFields = `orderItems {
  discount
  id
  itemId
  itemTitle
  orderId
  quantity
  unitPrice
  variantId
  variantTitle
  variant {
          image {
            source
          }
        }
}`

const ordersFields = `
  createdAt
  customerId
  refundDeliveryToStaff
  customer {
    id firstName lastName shopifyCustomerId email phone
  }
  chat_url
  deliverBy
  deliveryDestination
  deliveryStatus
  id
  isCollectedByAdmin
  note
  orderAddress
  paymentMethod
  paymentReceivedDate
  ${orderItemFields}
  paymentReceiverId
  shopifyOrderId
  shopifyOrderNumber
  source
  staffId
  status
  itemsPrice
  taxPrice
  discount
  subTotal
  deliveryPrice
  grandTotal
  boorranDeliveryPrice
  boorranGrandTotal
  paymentReceiver {
    id
    name
    photo
    role
  }
  staff {
    id
    name
    photo
    role
  }
  refundById
  delivererId
  packBy
  packStatus
  deliveryDate
  paymentMethodRequests { id }
  cancelRequests { id }
  location
`

const filterFields = ({
  orderTab,
  filters,
  ordering = 'id: desc',
  banks,
  cashAccount,
  userId = null,
}) => {
  let {
    paymentReceiverId,
    deliveryStatus,
    isCashOnly,
    isBankOnly,
    from,
    to,
    keywords,
    staffId,
    status,
    paymentMethod,
    deliverBy,
    isCollectedByAdmin,
    refundDeliveryToStaff,
    refundById,
    date,
    delivererId,
    packBy,
  } = filters
  from &&
    to &&
    ([from, to] = [
      new Date(new Date(from).setHours(0, 0, 0)),
      new Date(new Date(to).setHours(23, 59, 59)),
    ])
  date &&
    ([from, to] = [
      new Date(new Date(date).setHours(0, 0, 0)),
      new Date(new Date(date).setHours(23, 59, 59)),
    ])

  const tabQuery =
    orderTab && orderTab !== 'all'
      ? generateOrderTabCondition({ tab: orderTab, banks, cashAccount, userId })
      : null
  const filterStr = [
    keywords &&
      generateKeywordsQuery(keywords, [
        'deliveryDestination',
        'orderAddress',
        'note',
        'shopifyOrderId',
        'shopifyOrderNumber',
        { orderItems: ['itemTitle'] },
        { customer: ['firstName', 'lastName', 'phone'] },
      ]),
    staffId && staffId !== 'N/A' && `staffId: { _eq: "${staffId}" }`,
    staffId && staffId === 'N/A' && 'staffId: { _is_null: true }',
    paymentReceiverId &&
      paymentReceiverId !== 'N/A' &&
      `paymentReceiverId: { _eq: "${paymentReceiverId}" }`,
    paymentReceiverId && paymentReceiverId === 'N/A' && 'paymentReceiverId: { _is_null: true }',
    status && `status: { _eq: "${status}" }`,
    deliveryStatus && `deliveryStatus: { _eq: "${deliveryStatus}" }`,
    deliverBy && deliverBy !== 'N/A' && `deliverBy: { _eq: "${deliverBy}" }`,
    // (deliverBy && deliverBy === 'N/A') && `_or: [
    //   {deliverBy: {_eq: ""}},
    //   {deliverBy: {_is_null: true}}
    // ]`,
    isCollectedByAdmin && `isCollectedByAdmin: { _eq: ${isCollectedByAdmin === 'yes'} }`,
    refundDeliveryToStaff &&
      refundDeliveryToStaff !== 'N/A' &&
      `refundDeliveryToStaff: { _eq: "${refundDeliveryToStaff}" }`,
    // (refundDeliveryToStaff && refundDeliveryToStaff === 'N/A') && `_or: [
    //   {refundDeliveryToStaff: {_eq: ""}},
    //   {refundDeliveryToStaff: {_is_null: true}}
    // ]`,
    ((from && to) || isCashOnly || paymentMethod || isBankOnly) &&
      `_and: [
			${
        (from &&
          to &&
          `{ createdAt: { _gte: "${from.toISOString()}" } } { createdAt: { _lte: "${to.toISOString()}" } }`) ||
        ''
      }
			${
        (paymentMethod &&
          paymentMethod !== 'N/A' &&
          `{paymentMethod: { _eq: "${paymentMethod}" }}`) ||
        ''
      }
			${
        (paymentMethod &&
          paymentMethod === 'N/A' &&
          `{_or: [
        {paymentMethod: {_eq: ""}},
        {paymentMethod: {_is_null: true}}
      ]}`) ||
        ''
      }
			${(isCashOnly.length && `{paymentMethod: { _nin: [${isCashOnly}] }}`) || ''}
			${(isBankOnly.length && `{paymentMethod: { _in: [${isBankOnly}] }}`) || ''}
    ]`,
    refundById && refundById !== 'N/A' && `refundById: { _eq: "${refundById}"}`,
    refundById && refundById === 'N/A' && 'refundById: { _is_null: true }',
    delivererId && delivererId !== 'N/A' && `delivererId: {_eq: "${delivererId}"}`,
    delivererId && delivererId === 'N/A' && 'delivererId: { _is_null: true }',
    packBy && packBy !== 'N/A' && `packBy: { _eq: "${packBy}" }`,
    packBy && packBy === 'N/A' && 'packBy: { _is_null: true }',
  ]
    .filter((filter) => filter)
    .join(', ')

  return `order_by: { ${ordering} }, where: { ${tabQuery || filterStr} }`
}

const generateOrderTabCondition = ({ tab, banks, cashAccount, userId }) => {
  // condition for old unpack
  // _or: [
  //     {
  //       _and:[{status: { _eq: "paid" }}, {deliveryStatus: {_eq: "Pending"}}, {paymentMethod: {_in: [${banks}]}}]
  //     },
  //     {
  //       _and:[{status: { _eq: "unpaid" }}, {deliveryStatus: {_eq: "Pending"}}, {paymentMethod: {_nin: [${banks}]}}]
  //     }
  //   ]
  switch (tab) {
    case 'task':
      return `status: { _in: ["pending", "Pre-order"] }, staffId: { _eq: "${userId}"}`
    case 'pending':
      return 'status: { _in: ["pending", "Pre-order"] }'
    case 'preparing':
      return 'status: { _in: ["paid", "unpaid"] }, deliveryStatus: { _in: ["Pending", "Waiting", "Printing"] }'
    case 'shipping':
      return `status: { _in: ["paid", "unpaid"] }, deliveryStatus: { _eq: "Delivering" },
      _or: [
        {delivererId: {_is_null: false}},
        {deliverBy: {_is_null: false}}
      ]`
    case 'delivered':
      return 'status: { _in: ["paid", "unpaid"] }, deliveryStatus: { _eq: "Done" }, isCollectedByAdmin: { _eq: false }'
    case 'completed':
      return '_or: [{ status: { _in: ["cancelled", "exchanged", "refunded"] } }, { status: { _in: ["paid", "unpaid"] }, deliveryStatus: { _eq: "Done" }, isCollectedByAdmin: { _eq: true } }]'
    case 'unpaid':
      return 'status: { _eq: "unpaid" }'
    case 'unpaidCash':
      return `status: { _in: ["pending", "unpaid"] }, paymentMethod: {_nin: [${banks}]}, , deliveryStatus: { _eq: "Pending" }`
    case 'unpaidBank':
      return `status: { _in: ["pending", "unpaid"] }, paymentMethod: {_in: [${banks}]}, `
    case 'unpack':
      return `_or: [
        {
          _and:[{status: { _eq: "paid" }}, {deliveryStatus: {_eq: "Pending"}}]
        },
        {
          _and:[{status: { _eq: "unpaid" }}, {deliveryStatus: {_eq: "Pending"}}]
        }
      ]`
    case 'unship':
      return 'deliveryStatus: {_eq: "Waiting"}'
    case 'waiting':
      return `
        deliveryStatus: {_eq: "Waiting"},
        _or: [
          {_and: {
            status: {_eq: "unpaid"},
            paymentMethod: {_eq: " Cash on Delivery (COD)"},
            _or: [
              {_and: { deliverBy: {_is_null: true}, delivererId: {_is_null: true}}},
              {delivererId: {_eq: "${userId}"}},
            ]
          }},
          {_and: {
            status: {_eq: "paid"},
            isCollectedByAdmin: {_eq: true},
            paymentMethod: {_in: [${banks}]}
              _or: [
              {_and: { deliverBy: {_is_null: true}, delivererId: {_is_null: true}}},
              {delivererId: {_eq: "${userId}"}},
            ]
          }},
          {_and: {
            status: {_eq: "paid"},
            paymentMethod: {_in: [${cashAccount}]},
            paymentReceiverId: {_is_null: false},
              _or: [
              {_and: { deliverBy: {_is_null: true}, delivererId: {_is_null: true}}},
              {delivererId: {_eq: "${userId}"}},
            ]
          }}
        ]`
    case 'assignedDeliver':
      return `
        delivererId: {_eq: "${userId}"},
        deliveryStatus: {_eq: "Delivering"}`
    // _or:
    // [
    //   {_and: [{status: {_eq: "paid"}}, {paymentMethod: {_in: [${banks}]}}]},
    //   {_and: [{status: {_eq: "paid"}}, {paymentMethod: {_in: [${cashAccount}]}}]},
    //   {_and: [{status: {_eq: "unpaid"}}, {paymentMethod: {_nin: [${banks}]}}]}
    // ]
    case 'successDeliver':
      return `
          delivererId: {_eq: "${userId}"},
          paymentReceiverId: {_eq: "${userId}"},
          deliveryStatus: {_eq: "Done"},
          status: {_eq: "paid"},
          _or:
          [
            {
              paymentMethod: {_in: [${banks}]},
              refundDeliveryToStaff: {_eq: "not_yet"},
              isCollectedByAdmin: {_eq: true},
            },
            {
              paymentMethod: {_nin: [${banks}]}
            }
          ]
      `
    case 'completedDeliver':
      return `
            delivererId: {_eq: "${userId}"},
            paymentReceiverId: {_eq: "${userId}"},
            deliveryStatus: {_eq: "Done"},
            isCollectedByAdmin: {_eq: true},
            status: {_eq: "paid"},
            _or: [
              {
                  isCollectedByAdmin: {_eq: true},
                  refundDeliveryToStaff: {_eq: "done"},
                  paymentMethod: {_in: [${banks}]}
              },
              {
                  refundDeliveryToStaff: {_eq: "done"},
                  paymentMethod: {_nin: [${banks}]}
              }
            ]`
    default:
      return ''
  }
}

const generateKeywordsQuery = (keywords, fields, table) => `
    ${(!table && '_or: [') || ''}
      ${fields.map((field) =>
        typeof field === 'string'
          ? table
            ? `{${table}: {_and: [
            ${keywords
              .split(' ')
              .map((i) => `{ ${field}: { _ilike: "%${i}%" } }`)
              .join('')}
                ]}}`
            : `{_and: [
            ${keywords
              .split(' ')
              .map((i) => `{ ${field}: { _ilike: "%${i}%" } }`)
              .join('')}
                ]}`
          : generateKeywordsQuery(keywords, Object.values(field)[0], Object.keys(field)[0])
      )}
    ${(!table && ']') || ''}
  `

const gqRequest = async (query, variables) => {
  const sessionToken = Cookie.get('sessionToken')
  return GQL({
    url: HASURA_URL,
    headers: { Authorization: sessionToken },
    query,
    variables,
  })
}

const enumFields = 'id text type value metadata'

export default {
  qUpsertEnum: `
    mutation InsertEnum($obj: [Enums_insert_input!]!) {
      insert_Enums(objects: $obj, on_conflict: {constraint: Enums_pkey, update_columns: []}) {
        returning { ${enumFields} }
      }
    }
  `,
  qFetchEnums: `{
    Enums { ${enumFields} }
  }`,
  qFetchUsers: `{
    Users {
      id name activationStatus role metadata
    }
  }`,
  gqRequest,
  qSubscriptions: (
    { orderTab, filters, ordering, banks, cashAccount, userId = null },
    table = 'boorran_Orders',
    fields = ordersFields
  ) => `
  subscription($limit: Int, $offset: Int){
      ${table}(${filterFields({
    orderTab,
    filters,
    ordering,
    banks,
    cashAccount,
    userId,
  })} limit: $limit offset: $offset) {
        ${fields}
      }
    }
  `,
  qAggregate: (
    { orderTab, filters, ordering, banks, cashAccount, appendQuery, userId = null },
    table = 'boorran_Orders',
    extraFields
  ) => {
    const countOrderFilters = appendQuery || ''
    return `{
        ${table}_aggregate(${filterFields({
      orderTab,
      filters,
      ordering,
      banks,
      cashAccount,
      userId,
    })}) {
          aggregate {
            count ${extraFields || ''}
          }
        }
        ${countOrderFilters}
      }
      `
  },
  itemIdById: (id, table = 'boorran_Orders', fields = ordersFields) => `
  subscription {
      ${table}(where: { id: {_eq: "${id}" } }) {
        ${fields}
        ${
          table === 'boorran_Orders'
            ? 'OriginalOrder { id shopifyOrderNumber grandTotal deliveryPrice }'
            : ''
        }
      }
    }
  `,
  qUpsert: (table = 'boorran_Orders', columns, constraint = 'Orders_pkey') => `
    mutation Insert${table}($obj: [${table}_insert_input!]!) {
      insert_${table}(objects: $obj, on_conflict: {constraint: ${constraint}, update_columns: [${columns}]}) {
        returning {
          ${columns}
        }
      }
    }
  `,
  qActivityLog: ({ orderId }) => `
  subscription {
      boorran_Logs(where: {orderId: {_eq: "${orderId}"}}) {
        field
        user { name }
        created_at
        updated_at
      }
    }
  `,
  qOrderItems: ({ orderId }) => `
  query {
        boorran_OrderItems(where: {orderId: {_eq: "${orderId}"}}) {
          itemId
          itemTitle
          discount
          quantity
          unitPrice
          variantId
          variantTitle
          id
          orderId
        }
    }
  `,
  qModificationSubscription: `
  subscription {
      boorran_Orders(where: { _or: [
        { cancelRequests: { approved: { _eq: false } } }
        { paymentMethodRequests: { approved: { _eq: false } } }
      ] }) {
        id
        shopifyOrderNumber
        shopifyOrderId
        grandTotal
        paymentMethodRequests(where: { approved: { _eq: false } }) {
          id
          created_at
          image
          paymentMethod
          deliverer {
            name
          }
        }
        cancelRequests(where: { approved: { _eq: false } }) {
          id
          isReturn
          reappointDateTime
          remark
          created_at
          deliverer {
            name
          }
        }
      }
    }
  `,
}
